@charset "UTF-8";
/* 余白 8の倍数 */
/*
top
*/
/* ==[ .p-top-mv ]==================================== */
.p-top-mv {
  position: relative;
}
.p-top-mv .mv-block {
  padding-bottom: 50px;
  margin: 0 auto;
  width: 89.5833333333vw;
  position: relative;
  overflow: hidden;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-mv .mv-block {
    padding-bottom: 70px;
    width: 100%;
  }
}
.p-top-mv .mv-block .swiper-slide {
  padding: 0 30px;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block .swiper-slide {
    padding: 0 20px;
  }
}
.p-top-mv .mv-block .swiper-slide__img {
  width: 100%;
  max-width: 100%;
  height: 600px;
}
@media not all and (min-width: 768px) {
  .p-top-mv .mv-block .swiper-slide__img {
    height: auto;
  }
}
.p-top-mv .mv-block .swiper-slide__img img {
  height: 100%;
}
.p-top-mv .mv-block .swiper-button-prev,
.p-top-mv .mv-block .swiper-button-next {
  height: 60px;
  width: 60px;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block .swiper-button-prev,
.p-top-mv .mv-block .swiper-button-next {
    height: 40px;
    width: 40px;
  }
}
.p-top-mv .mv-block .swiper-button-prev::after,
.p-top-mv .mv-block .swiper-button-next::after {
  content: "";
  margin: auto;
  height: 60px;
  width: 60px;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block .swiper-button-prev::after,
.p-top-mv .mv-block .swiper-button-next::after {
    height: 40px;
    width: 40px;
  }
}
.p-top-mv .mv-block .swiper-button-prev {
  left: 0;
}
.p-top-mv .mv-block .swiper-button-prev::after {
  background: url(../img/common/icon_slider_prev.svg) no-repeat center center;
  background-size: 100% auto;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block .swiper-button-prev::after {
    background: url(../img/common/icon_slider_prev_sp.svg) no-repeat center center;
  }
}
.p-top-mv .mv-block .swiper-button-next {
  right: 0;
}
.p-top-mv .mv-block .swiper-button-next::after {
  background: url(../img/common/icon_slider_next.svg) no-repeat center center;
  background-size: 100% auto;
}
@media not all and (min-width: 992px) {
  .p-top-mv .mv-block .swiper-button-next::after {
    background: url(../img/common/icon_slider_next_sp.svg) no-repeat center center;
  }
}
.p-top-mv .mv-block .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 30px;
  bottom: 30px;
}
@media screen and (max-width: 1480px) {
  .p-top-mv .mv-block .swiper-pagination {
    justify-content: flex-end;
    padding: 0 20px;
    width: calc(100% - 600px);
  }
}
@media not all and (min-width: 768px) {
  .p-top-mv .mv-block .swiper-pagination {
    gap: 0 20px;
    justify-content: flex-end;
    bottom: 60px;
    width: 100%;
  }
}
.p-top-mv .mv-block .swiper-pagination .swiper-pagination-bullet {
  background: #C5C5C5;
  width: 10px;
  height: 10px;
  opacity: 1;
}
.p-top-mv .mv-block .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #1E1E1E;
}
.p-top-mv .mv-block .mv-heading-block {
  color: white;
  text-align: center;
  padding: 0 15px;
  width: calc(100% - 60px);
  max-width: 100%;
  padding: 0 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-mv .mv-block .mv-heading-block {
    width: calc(100% - 64px);
    bottom: 0;
  }
}
.p-top-mv .mv-block .mv-heading-block .sub-heading {
  font-size: calc(18px + 12 * (100vw - 428px) / 1492);
}
.p-top-mv .mv-block .mv-heading-block .main-heading {
  font-size: calc(28px + 24 * (100vw - 428px) / 1492);
  font-weight: 700;
  margin-bottom: 30px;
}
@media not all and (min-width: 600px) {
  .p-top-mv .mv-block .mv-heading-block .main-heading {
    margin-bottom: 20px;
  }
}
.p-top-mv .mv-block .mv-heading-block img {
  width: auto;
}
.p-top-mv .info-block {
  position: absolute;
  bottom: 100px;
  left: 50px;
  z-index: 1;
}
@media not all and (min-width: 768px) {
  .p-top-mv .info-block {
    bottom: 0;
    left: 20px;
  }
}
.p-top-mv .info-block img {
  width: 13.0208333333vw;
  max-width: 250px;
  min-width: 140px;
}
.p-top-mv .topics-block {
  width: 66.666%;
  max-width: 600px;
  position: absolute;
  bottom: 0;
  right: 2.6041666667vw;
  z-index: 2;
  overflow: hidden;
}
@media not all and (min-width: 992px) {
  .p-top-mv .topics-block {
    right: 0;
  }
}
@media not all and (min-width: 768px) {
  .p-top-mv .topics-block {
    margin: 0 auto;
    width: calc(100% - 40px);
    max-width: 100%;
    position: static;
    bottom: auto;
    right: auto;
  }
}
.p-top-mv .topics-block__inner {
  background-color: #F9EE40;
  border-radius: 10px;
  padding: 30px;
  height: 180px;
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  .p-top-mv .topics-block__inner {
    padding: 20px;
    height: 150px;
  }
}
.p-top-mv .topics-block__ttl {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: calc(30px + 12 * (100vw - 428px) / 1492);
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: normal;
  margin-bottom: 5px;
}
.p-top-mv .topics-block .slider-topics {
  height: 100%;
}
.p-top-mv .topics-block .slider-topics .swiper-slide {
  line-height: normal;
}
.p-top-mv .topics-block .slider-topics .swiper-slide__link {
  color: #1E1E1E;
  font-weight: 500;
  line-height: 1.875;
  text-decoration: none;
  min-height: 80px;
}
@media not all and (min-width: 768px) {
  .p-top-mv .topics-block .slider-topics .swiper-slide__link {
    font-size: 15px;
  }
}

/* ==[ .p-top-news ]==================================== */
@media not all and (min-width: 768px) {
  .p-top-news .main-ttl__en {
    margin-bottom: 20px;
  }
}
.p-top-news .news-card .card-footer,
.p-top-news .blog-card .card-footer {
  padding-top: 60px;
}
@media not all and (min-width: 768px) {
  .p-top-news .news-card .card-footer,
.p-top-news .blog-card .card-footer {
    padding-top: 40px;
  }
}
.p-top-news .news-card .card-footer .btn,
.p-top-news .blog-card .card-footer .btn {
  margin: 0 auto;
}
.p-top-news .news-card {
  padding-right: 45px;
}
@media not all and (min-width: 992px) {
  .p-top-news .news-card {
    padding-right: 0;
  }
}
.p-top-news .blog-card {
  padding-left: 45px;
}
@media not all and (min-width: 992px) {
  .p-top-news .blog-card {
    padding-left: 0;
  }
}
@media not all and (min-width: 768px) {
  .p-top-news .blog-card {
    margin-top: 70px;
  }
}

/* ==[ .p-top-info ]==================================== */
.p-top-info .bnr-unit {
  width: 100%;
}
@media not all and (min-width: 768px) {
  .p-top-info .bnr-unit {
    flex-wrap: wrap;
  }
}
.p-top-info .bnr-unit li {
  width: 50%;
}
@media not all and (min-width: 768px) {
  .p-top-info .bnr-unit li {
    width: 100%;
  }
}

/* ==[ .p-top-about ]==================================== */
.p-top-about {
  background: #f4f0e9;
  background: linear-gradient(0deg, #f4f0e9 0%, rgba(255, 255, 255, 0) 100%);
}
.p-top-about .sec-inner {
  background: url(../img/top/about/about_bg.png) no-repeat center top;
  background-size: cover;
  padding-bottom: 100px;
}
.p-top-about .p-ttl-unit {
  margin-bottom: 60px;
}
.p-top-about .contxt {
  font-size: 15px;
  margin-top: 40px;
  text-align: center;
}
@media not all and (min-width: 768px) {
  .p-top-about .contxt {
    text-align: left;
  }
}
.p-top-about .card-unit {
  gap: 0 60px;
  margin-bottom: 50px;
}
@media not all and (min-width: 992px) {
  .p-top-about .card-unit {
    gap: 0 30px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-about .card-unit {
    gap: 30px 0;
    flex-wrap: wrap;
  }
}
.p-top-about .about-card {
  border: 3px #C5C5C5 solid;
  padding: 30px;
  width: 50%;
}
@media not all and (min-width: 768px) {
  .p-top-about .about-card {
    padding: 30px 20px;
    width: 100%;
  }
}
.p-top-about .about-card .card-header {
  text-align: center;
  padding-bottom: 20px;
}
.p-top-about .about-card .card-title {
  color: #11A730;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
}
.p-top-about .p-btn-unit {
  margin-bottom: 80px;
}
.p-top-about .btn {
  margin: 0 auto;
}
.p-top-about .yt-block iframe {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

/* ==[ .p-top-course ]==================================== */
.p-top-course .card-unit {
  gap: 60px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
@media not all and (min-width: 992px) {
  .p-top-course .card-unit {
    gap: 30px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-course .card-unit {
    gap: 20px 0;
  }
}
.p-top-course .course-card {
  border: 3px #11A730 solid;
  color: #1E1E1E;
  text-decoration: none;
  min-height: 300px;
  width: calc(50% - 30px);
}
@media not all and (min-width: 768px) {
  .p-top-course .course-card {
    min-height: 285px;
    width: 100%;
  }
}
.p-top-course .course-card.course1 {
  background: url(../img/top/course/course_img1.jpg) no-repeat center center;
  background-size: cover;
}
.p-top-course .course-card.course2 {
  background: url(../img/top/course/course_img2.jpg) no-repeat center center;
  background-size: cover;
}
.p-top-course .course-card.course3 {
  background: url(../img/top/course/course_img3.jpg) no-repeat center center;
  background-size: cover;
}
.p-top-course .course-card.course4 {
  background: url(../img/top/course/course_img4.jpg) no-repeat center center;
  background-size: cover;
}
.p-top-course .course-card .card-header {
  background-color: #11A730;
  padding: 10px 30px;
}
.p-top-course .course-card .card-body {
  padding: 20px 30px;
}
.p-top-course .course-card .card-footer {
  margin-top: auto;
  padding: 0 30px 30px;
}
.p-top-course .course-card .card-title {
  color: white;
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
}
.p-top-course .course-card .card-list li::before {
  content: "●";
  display: inline-block;
}

/* ==[ .p-top-map ]==================================== */
.p-top-map {
  background-color: #F2F2F2;
}
.p-top-map .map-block {
  background-color: white;
}
@media not all and (min-width: 768px) {
  .p-top-map .map-block {
    flex-wrap: wrap;
  }
}
.p-top-map .map-block__item--inner {
  padding: 20px 20px 30px;
  width: 400px;
  height: 620px;
  overflow: hidden;
}
@media not all and (min-width: 992px) {
  .p-top-map .map-block__item--inner {
    width: 300px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-map .map-block__item--inner {
    width: 100%;
  }
}
@media not all and (min-width: 768px) {
  .p-top-map .map-block__item.left-item {
    width: 100%;
  }
}
.p-top-map .map-block__item.left-item .gMap-nav {
  height: 100%;
  overflow-y: auto;
}
.p-top-map .map-block__item.left-item .gMap-nav::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}
.p-top-map .map-block__item.left-item .gMap-nav::-webkit-scrollbar-track {
  background: white;
}
.p-top-map .map-block__item.left-item .gMap-nav::-webkit-scrollbar-thumb {
  background: #C5C5C5;
  border-radius: 5px;
}
.p-top-map .map-block__item.left-item .nav-tabs {
  border-bottom: none;
  gap: 30px 0;
  padding-right: 10px;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link {
  border: none;
  color: #1E1E1E;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  width: 100%;
  transition: 0.3s;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link .school-ttl {
  font-size: 18px;
  font-weight: 500;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link address,
.p-top-map .map-block__item.left-item .nav-tabs .nav-link .tel {
  font-size: 15px;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link .tel::before {
  content: "TEL. ";
  display: inline-block;
  padding-right: 5px;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link.active {
  border: none;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link.active .school-ttl {
  color: #11A730;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link:hover {
  border: none;
  color: #1E1E1E;
  opacity: 1;
}
.p-top-map .map-block__item.left-item .nav-tabs .nav-link:hover .school-ttl {
  color: #11A730;
}
.p-top-map .map-block__item.right-item {
  display: block;
  height: 620px;
  width: calc(100% - 400px);
}
@media not all and (min-width: 992px) {
  .p-top-map .map-block__item.right-item {
    width: calc(100% - 300px);
  }
}
@media not all and (min-width: 768px) {
  .p-top-map .map-block__item.right-item {
    height: 200px;
    width: 100%;
  }
}
.p-top-map .map-block__item.right-item .tab-content {
  height: 100%;
}
.p-top-map .map-block__item.right-item .tab-pane.active {
  height: 100%;
}
.p-top-map .map-block__item.right-item .gMap-block {
  width: 100%;
  height: 100%;
}
.p-top-map .map-block__item.right-item .gMap-block iframe {
  width: 100%;
  height: 100%;
}
.p-top-map .p-btn-unit {
  margin-top: 60px;
}
@media not all and (min-width: 768px) {
  .p-top-map .p-btn-unit {
    margin-top: 40px;
  }
}
.p-top-map .p-btn-unit .btn {
  margin: 0 auto;
}

/* ==[ .p-top-recruit ]==================================== */
@media not all and (min-width: 768px) {
  .p-top-recruit {
    background-color: #CFEDD6;
  }
}
.p-top-recruit .sec-inner {
  padding: 0;
}
.p-top-recruit .p-recruit-header {
  height: 400px;
  position: relative;
}
.p-top-recruit .p-recruit-header .card-img {
  height: 100%;
  position: relative;
}
@media not all and (min-width: 992px) {
  .p-top-recruit .p-recruit-header .card-img::after {
    background: #cfedd6;
    background: linear-gradient(45deg, #cfedd6 0%, rgba(255, 255, 255, 0) 80%);
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.p-top-recruit .p-recruit-header .card-img img {
  width: auto;
  height: 100%;
}
.p-top-recruit .p-recruit-header .card-img img.object-fit-img {
  object-position: right center;
}
.p-top-recruit .container-fluid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media not all and (min-width: 768px) {
  .p-top-recruit .container-fluid {
    position: static;
    top: auto;
    left: auto;
    transform: translate(0, 0);
  }
}
.p-top-recruit .left-col {
  height: 100%;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .right-col {
    padding: 40px 20px;
    text-align: center;
  }
}
.p-top-recruit .p-ttl-unit {
  text-align: left;
  margin-bottom: 50px;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .p-ttl-unit {
    text-align: center;
  }
}
.p-top-recruit .main-ttl__en {
  color: #1E1E1E;
}
.p-top-recruit .contxt {
  font-weight: 500;
  letter-spacing: 0.1em;
}
.p-top-recruit .p-btn-unit {
  margin-top: 50px;
}
@media not all and (min-width: 768px) {
  .p-top-recruit .btn {
    margin: 0 auto;
  }
}

/* ==[ .p-top-sns ]==================================== */
.p-top-sns .sns-unit {
  gap: 0 26px;
  align-items: center;
  justify-content: center;
}
@media not all and (min-width: 992px) {
  .p-top-sns .sns-unit {
    gap: 10px;
  }
}
@media not all and (min-width: 768px) {
  .p-top-sns .sns-unit {
    flex-wrap: wrap;
  }
}
.p-top-sns .sns-unit li {
  width: calc(25% - 5px);
}
@media not all and (min-width: 768px) {
  .p-top-sns .sns-unit li {
    width: calc(50% - 5px);
  }
}
.p-top-sns .sns-unit li a {
  display: block;
}
.p-top-sns .sns-unit li img {
  width: 100%;
}

/* ==[ .p-top-contact ]==================================== */
.p-top-contact {
  background-color: #F2F2F2;
}
.p-top-contact .sec-inner {
  padding: 60px 0;
}
@media not all and (min-width: 768px) {
  .p-top-contact .c-contact-unit {
    flex-wrap: wrapw;
  }
}
.p-top-contact .c-contact-unit li {
  text-align: center;
}
@media not all and (min-width: 768px) {
  .p-top-contact .c-contact-unit li {
    width: 100%;
  }
}