@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
top
*/
$PAGE_ID: top;
$PATH: '../';
$COMMON_PATH: '#{$PATH}img/common/';
$IMAGE_PATH: '#{$PATH}img/#{$PAGE_ID}/';

/* ==[ .p-top-mv ]==================================== */
.p-top-mv {
  position: relative;

  .mv-block {
    padding-bottom: 50px;
    margin: 0 auto;
    width: calc(1720 / 1920 * 100vw);
    position: relative;
    overflow: hidden;

    @include mixin.tabletL {
      width: 100%;
    }

    @include mixin.tabletP {
      padding-bottom: 70px;
      width: 100%;
    }

    .swiper-slide {
      padding: 0 30px;

      @include mixin.tabletL {
        padding: 0 20px;
      }

      &__img {
        width: 100%;
        max-width: 100%;
        height: 600px;

        @include mixin.tabletP {
          height: auto;
        }

        img {
          height: 100%;
        }
      }

    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 60px;
      width: 60px;

      @include mixin.tabletL {
        height: 40px;
        width: 40px;
      }

      &::after {
        content: "";
        margin: auto;
        height: 60px;
        width: 60px;

        @include mixin.tabletL {
          height: 40px;
          width: 40px;
        }
      }
    }

    .swiper-button-prev {
      left: 0;

      &::after {
        background: url(#{mixin.set_common_path('icon_slider_prev.svg')}) no-repeat center center;
        background-size: 100% auto;

        @include mixin.tabletL {
          background: url(#{mixin.set_common_path('icon_slider_prev_sp.svg')}) no-repeat center center;
        }
      }
    }

    .swiper-button-next {
      right: 0;

      &::after {
        background: url(#{mixin.set_common_path('icon_slider_next.svg')}) no-repeat center center;
        background-size: 100% auto;

        @include mixin.tabletL {
          background: url(#{mixin.set_common_path('icon_slider_next_sp.svg')}) no-repeat center center;
        }
      }
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 30px;
      bottom: 30px;

      @media screen and (max-width: 1480px ) {
        justify-content: flex-end;
        padding: 0 20px;
        width: calc(100% - 600px);
      }

      @include mixin.tabletP {
        gap: 0 20px;
        justify-content: flex-end;
        bottom: 60px;
        width: 100%;
      }

      .swiper-pagination-bullet {
        background: map_get(variables.$COLOR, gray);
        width: 10px;
        height: 10px;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: variables.$COLOR_TEXT;
        }
      }
    }

    .mv-heading-block {
      color: white;
      text-align: center;
      padding: 0 15px;
      width: calc(100% - 60px);
      max-width: 100%;
      padding: 0 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;

      @include mixin.tabletP {
        width: calc(100% - 64px);
        bottom: 0;
      }

      .sub-heading {
        font-size: calc(18px + 12 * (100vw - 428px) / 1492);
      }

      .main-heading {
        font-size: calc(28px + 24 * (100vw - 428px) / 1492);
        font-weight: 700;
        margin-bottom: 30px;

        @include mixin.sp {
          margin-bottom: 20px;
        }
      }

      img {
        width: auto;
      }
    }
  }

  .info-block {
    position: absolute;
    bottom: 100px;
    left: 50px;
    z-index: 1;

    @include mixin.tabletP {
      bottom: 0;
      left: 20px;
    }

    img {
      width: calc(250 / 1920 * 100vw);
      max-width: 250px;
      min-width: 140px;
    }
  }

  .topics-block {
    width: 66.666%;
		max-width: 600px;
    position: absolute;
    bottom: 0;
    right: calc(50 / 1920 * 100vw);
    z-index: 2;
    overflow: hidden;

    @include mixin.tabletL {
      right: 0;
    }

    @include mixin.tabletP {
      margin: 0 auto;
      width: calc(100% - 40px);
      max-width: 100%;
      position: static;
      bottom: auto;
      right: auto;
    }

    &__inner {
      background-color: map_get(variables.$COLOR, yellow);
      border-radius: 10px;
      padding: 30px;
      height: 180px;
      overflow: hidden;

      @include mixin.tabletP {
        padding: 20px;
        height: 150px;
      }
    }

    &__ttl {
      font-family: variables.$FONT_ALPHA;
      font-size: calc(30px + 12 * (100vw - 428px) / 1492);
      font-weight: 500;
      letter-spacing: .05em;
      line-height: normal;
      margin-bottom: 5px;
    }

    .slider-topics {
      height: 100%;

      .swiper-slide {
        line-height: normal;

        &__link {
          color: variables.$COLOR_TEXT;
          font-weight: 500;
          line-height: 1.875;
          text-decoration: none;
          min-height: 80px;

          @include mixin.tabletP {
            font-size: 15px;
          }
        }
      }
    }
  }
}

/* ==[ .p-top-news ]==================================== */
.p-top-news {

  .main-ttl__en {
    @include mixin.tabletP {
      margin-bottom: 20px;
    }
  }

  .news-card,
  .blog-card {
    .card-footer {
      padding-top: 60px;

      @include mixin.tabletP {
        padding-top: 40px;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }

  .news-card {
    padding-right: 45px;

    @include mixin.tabletL {
      padding-right: 0;
    }
  }

  .blog-card {
    padding-left: 45px;
    @include mixin.tabletL {
      padding-left: 0;
    }

    @include mixin.tabletP {
      margin-top: 70px;
    }
  }
}

/* ==[ .p-top-info ]==================================== */
.p-top-info {
  .bnr-unit {
    width: 100%;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    li {
      width: calc(100% / 2);

      @include mixin.tabletP {
        width: 100%;
      }
    }
  }
}

/* ==[ .p-top-about ]==================================== */
.p-top-about {
  background: rgb(244,240,233);
  background: linear-gradient(0deg, rgba(244,240,233,1) 0%, rgba(255,255,255,0) 100%);

  .sec-inner {
    background: url(#{mixin.set_image_path('top/about/about_bg.png')}) no-repeat center top;
    background-size: cover;
    padding-bottom: 100px;
  }

  .p-ttl-unit {
    margin-bottom: 60px;
  }

  .contxt {
    font-size: 15px;
    margin-top: 40px;
    text-align: center;

    @include mixin.tabletP {
      text-align: left;
    }
  }

  .card-unit {
    gap: 0 60px;
    margin-bottom: 50px;

    @include mixin.tabletL {
      gap: 0 30px;
    }

    @include mixin.tabletP {
      gap: 30px 0;
      flex-wrap: wrap;
    }
  }

  .about-card {
    border: 3px map_get(variables.$COLOR, gray) solid;
    padding: 30px;
    width: calc(100% / 2);

    @include mixin.tabletP {
      padding: 30px 20px;
      width: 100%;
    }

    .card-header {
      text-align: center;
      padding-bottom: 20px;
    }

    .card-title {
      color: variables.$COLOR_MAIN;
      font-size: 25px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .p-btn-unit {
    margin-bottom: 80px;
  }

  .btn {
    margin: 0 auto;
  }

  .yt-block {

    iframe {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

/* ==[ .p-top-course ]==================================== */
.p-top-course {

  .card-unit {
    gap: 60px;
    flex-wrap: wrap;
    margin-bottom: 50px;

    @include mixin.tabletL {
      gap: 30px;
    }

    @include mixin.tabletP {
      gap: 20px 0;
    }
  }

  .course-card {
    border: 3px variables.$COLOR_MAIN solid;
    color: variables.$COLOR_TEXT;
    text-decoration: none;
    min-height: 300px;
    width: calc(100% / 2 - 30px);

    @include mixin.tabletP {
      min-height: 285px;
      width: 100%;
    }

    &.course1 {
      background: url(#{mixin.set_image_path('top/course/course_img1.jpg')}) no-repeat center center;
      background-size: cover;
    }

    &.course2 {
      background: url(#{mixin.set_image_path('top/course/course_img2.jpg')}) no-repeat center center;
      background-size: cover;
    }

    &.course3 {
      background: url(#{mixin.set_image_path('top/course/course_img3.jpg')}) no-repeat center center;
      background-size: cover;
    }

    &.course4 {
      background: url(#{mixin.set_image_path('top/course/course_img4.jpg')}) no-repeat center center;
      background-size: cover;
    }

    .card-header {
      background-color: variables.$COLOR_MAIN;
      padding: 10px 30px;
    }

    .card-body {
      padding: 20px 30px;
    }

    .card-footer {
      margin-top: auto;
      padding: 0 30px 30px;
    }

    .card-title {
      color: white;
      font-size: 25px;
      font-weight: 500;
      line-height: normal;
    }

    .card-text {
    }

    .card-list {

      li {

        &::before {
          content: "●";
          display: inline-block;
        }
      }
    }
  }
}

/* ==[ .p-top-map ]==================================== */
.p-top-map {
  background-color: map_get(variables.$COLOR, palegray);

  .map-block {
    background-color: white;

    @include mixin.tabletP {
      flex-wrap: wrap;
    }

    &__item {

      &--inner {
        padding: 20px 20px 30px;
        width: 400px;
        height: 620px;
        overflow: hidden;

        @include mixin.tabletL {
          width: 300px;
        }

        @include mixin.tabletP {
          width: 100%;
        }
      }

      &.left-item {

        @include mixin.tabletP {
          width: 100%;
        }

        .gMap-nav {
          height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 2px;
            height: 6px;
          }

          &::-webkit-scrollbar-track {
            background: white;
          }

          &::-webkit-scrollbar-thumb {
            background: map_get(variables.$COLOR, gray);
            border-radius: 5px;
          }
        }

        .nav-tabs {
          border-bottom: none;
          gap: 30px 0;
          padding-right: 10px;

          .nav-link {
            border: none;
            color: variables.$COLOR_TEXT;
            margin-bottom: 0;
            padding: 0;
            text-align: left;
            text-decoration: none;
            width: 100%;
            transition: .3s;

            .school-ttl {
              font-size: 18px;
              font-weight: 500;
            }

            address,
            .tel {
              font-size: 15px;
            }

            .tel {
              &::before {
                content: "TEL. ";
                display: inline-block;
                padding-right: 5px;
              }
            }

            &.active {
              border: none;

              .school-ttl {
                color: variables.$COLOR_MAIN;
              }
            }

            &:hover {
              border: none;
              color: variables.$COLOR_TEXT;
              opacity: 1;

              .school-ttl {
                color: variables.$COLOR_MAIN;
              }
            }
          }
        }
      }

      &.right-item {
        display: block;
        height: 620px;
        width: calc(100% - 400px);

        @include mixin.tabletL {
          width: calc(100% - 300px);
        }

        @include mixin.tabletP {
          height: 200px;
          width: 100%;
        }

        .tab-content {
          height: 100%;
        }

        .tab-pane {

          &.active {
            height: 100%;
          }
        }

        .gMap-block {
          width: 100%;
          height: 100%;

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .p-btn-unit {
    margin-top: 60px;

    @include mixin.tabletP {
      margin-top: 40px;
    }

    .btn {
      margin: 0 auto;
    }
  }
}

/* ==[ .p-top-recruit ]==================================== */
.p-top-recruit {
  @include mixin.tabletP {
    background-color: map_get(variables.$COLOR, green);
  }

  .sec-inner {
    padding: 0;
  }

  .p-recruit-header {
    height: 400px;
    position: relative;

    .card-img {
      height: 100%;
      position: relative;

      &::after {
        @include mixin.tabletL {
          background: rgb(207,237,214);
          background: linear-gradient(45deg, rgba(207,237,214,1) 0%, rgba(255,255,255,0) 80%);
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      img {
        width: auto;
        height: 100%;

        &.object-fit-img {
          object-position: right center;
        }
      }
    }
  }

  .container-fluid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mixin.tabletP {
      position: static;
      top: auto;
      left: auto;
      transform: translate(0, 0);
    }
  }

  .left-col {
    height: 100%;
  }

  .right-col {

    @include mixin.tabletP {
      padding: 40px 20px;
      text-align: center;
    }
  }

  .p-ttl-unit {
    text-align: left;
    margin-bottom: 50px;

    @include mixin.tabletP {
      text-align: center;
    }
  }

  .main-ttl__en {
    color: variables.$COLOR_TEXT;
  }

  .contxt {
    font-weight: 500;
    letter-spacing: .1em;
  }

  .p-btn-unit {
    margin-top: 50px
  }

  .btn {
    @include mixin.tabletP {
      margin: 0 auto;
    }
  }
}

/* ==[ .p-top-sns ]==================================== */
.p-top-sns {
  .sns-unit {
    gap: 0 26px;
    align-items: center;
    justify-content: center;

    @include mixin.tabletL {
      gap: 10px;
    }

    @include mixin.tabletP {
      flex-wrap: wrap;
    }


    li {
      width: calc(100% / 4 - 5px);

      @include mixin.tabletP {
        width: calc(100% / 2 - 5px);
      }

      a {
        display: block;
      }

      img {
        width: 100%;
      }
    }
  }



}

/* ==[ .p-top-contact ]==================================== */
.p-top-contact {
  background-color: map_get(variables.$COLOR, palegray);

  .sec-inner {
    padding: 60px 0;
  }

  .c-contact-unit {

    @include mixin.tabletP {
      flex-wrap: wrapw;
    }

    li {
      text-align: center;

      @include mixin.tabletP {
        width: 100%;
      }
    }

  }
}
