// color
$COLOR: (
  blue: #0B76B8,
  palegreen: #CFEDD6,
  green: #CFEDD6,
	palegray: #F2F2F2,
	gray: #C5C5C5,
  purple: #2E3689,
  yellow: #F9EE40
) !default;

$COLOR_TEXT: #1E1E1E;
$COLOR_MAIN: #11A730;

// base
$BASE: (
	font-size: 15px,
	line-height: 1.875,
) !default;

// brake-point
$BRAKE_POINT: (
  sp: 600px,
  tbP: 768px,
  tbL: 992px,
  pc: 1200px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1200px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN', sans-serif;
$FONT_ALPHA: 'Poppins', Arial, Helvetica, sans-serif;
$FONT_ALPHA_SPARTAN: 'Spartan', Arial, Helvetica, sans-serif;
$FONT_MINCHO: "Ryumin Medium KL", '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';
$FONT_NUMBER: 'Barlow', sans-serif;
